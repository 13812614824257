//@ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
// import { useFormik } from "formik";
import { getUserPermission, login, getUserByToken, otpGeneration, otpValidate, otpResend } from "../core/_requests";
import { toAbsoluteUrl } from "../../../../_metronic/helpers/AssetHelpers";
import OTPInputGroup from "./OTP/otpInput"
import Timer from "./OTP/Timer"
import { useAuth } from "../core/Auth";
import CryptoJS from "crypto-js";
import "./OTP/style.css"
import { setPermissions, setUser } from "../core/AuthHelpers";
import { LoadingSpinner } from "../../../commonComponents/LoadingSpinner";
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email ID is required"),
});

const initialValues = {
  email: "",
  password: "",
};

// const initialValues = {
//   email: '',
//   password: '',
// }

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function EmailLogin() {
  const [loading, setLoading] = useState(false);
  const { auth, saveAuth, setCurrentUser, savePermissions } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [otpRequest, setOtpRequest] = useState(0);
  const [otp, setOtpValue] = useState("");
  const [token, setToken] = useState("");
  const [details, setDetails] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [counter, setCounter] = useState<any>();
  const [otpError, setOtpError] = useState();
  const [browserName, setBrowserName] = useState("");
  const [osName, setOSName] = useState("");
  const [isOtpLogin, setOtpLogin] = useState(false);

const TOKEN_DECRYPT_KEY = "admi$3$#@$^@1ERF";


  const handleOtp = () => {
    setOtpLogin(!isOtpLogin);
    setOtpValue("");
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleOTPValue = (data) => {
    setOtpValue(data);
  };

  var validRegEmail =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/

  const onEmailSubmit = async () => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) {
      if (email) {
        const values = {
          email: email,
        };
        const jsonString = JSON.stringify(values);
      
        const key = CryptoJS.enc.Utf8.parse(TOKEN_DECRYPT_KEY);
        const iv = CryptoJS.lib.WordArray.create(key.words.slice(0, 4), 16); // 4 words * 4 bytes = 16 bytes
        const encrypted = CryptoJS.AES.encrypt(jsonString, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        const encryptedBase64 = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
      
        setCounter(180);
        try {
          setLoading(true);
          const response = await otpGeneration({ request: encryptedBase64 });
          const {
            status,
            data: { authResponse },
          } = response;
      
          if (status === 200) {
            if (authResponse) {
              const decryptAuthResponse = (encryptedText) => {
                const encryptedWordArray = CryptoJS.enc.Base64.parse(encryptedText);
                const decrypted = CryptoJS.AES.decrypt(
                  { ciphertext: encryptedWordArray },
                  key,
                  {
                    iv: iv,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7,
                  }
                );
                return decrypted.toString(CryptoJS.enc.Utf8);
              };
      
              const decryptedAuthResponse = decryptAuthResponse(authResponse);
              const otpRequestValue = JSON.parse(decryptedAuthResponse).otpRequest;      
              setOtpRequest(otpRequestValue);
            } else {
              setOtpRequest(otpRequestValue);
            }
      
            handleOtp();
            setLoading(false);
          }
        } catch (error) {
          console.error("Error in OTP generation:", error);
          setMailError(error.response?.data?.message || "An error occurred");
          setTimeout(() => setMailError(""), 10000);
           setLoading(false);
        }
      }
      
    } else {
      // setMailError(true);
      // setTimeout(() => setMailError(false), 5000);
      setLoading(false);
    }
  };

  // console.log("OTP Req no:", otpRequest)


  const onOtpSumbit = async () => {
    try {
      const values = {
        email: email,
        otpRequest,
        otp: parseInt(otp),
      };
      const jsonString = JSON.stringify(values);
  
      const key = CryptoJS.enc.Utf8.parse(TOKEN_DECRYPT_KEY);
      const iv = CryptoJS.lib.WordArray.create(key.words.slice(0, 4), 16); // 4 words * 4 bytes = 16 bytes
      const encrypted = CryptoJS.AES.encrypt(jsonString, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      const encryptedBase64 = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
      setLoading(true);
      const response = await otpValidate({ request: encryptedBase64 });
  
      const {
        status,
        data: { authResponse },
      } = response;
  
      if (status === 200 && authResponse) {
        const decryptAuthResponse = (encryptedText) => {
          const encryptedWordArray = CryptoJS.enc.Base64.parse(encryptedText);
          const decrypted = CryptoJS.AES.decrypt(
            { ciphertext: encryptedWordArray },
            key,
            {
              iv: iv,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          );
          return decrypted.toString(CryptoJS.enc.Utf8);
        };
  
        const decryptedAuthResponse = decryptAuthResponse(authResponse);
  
        const tokenValue = JSON.parse(decryptedAuthResponse);
        setLoading(false);
        if (tokenValue) {
          setToken(tokenValue);
          saveAuth(tokenValue);
  
          const {
            data: { data: user },
          } = await getUserByToken(tokenValue);
  
          const {
            data: { data: permission },
          } = await getUserPermission(tokenValue);
  
          savePermissions(permission);
          setCurrentUser(user);
  
          setUser({ email: email });
          setEmail("");
          setOtpRequest(0);
          handleOtp();
          console.log("auth:", tokenValue);
        }
      } else {
        throw new Error("Invalid response or missing authResponse");
      }
  
      setLoading(false);
    } catch (error) {
      console.error("Error during OTP submission:", error);
      setOtpError(error.response?.data?.message || "An error occurred");
      setTimeout(() => setOtpError(""), 10000);
      saveAuth(undefined);
      setLoading(false);
    }
  };

  console.log("Token::", token)
  

  const resendOTP = async () => {
    const values = {
      email: email,
      // browser: browserName,
      // os: osName,
      // deviceType: "Web",
      // appVersion: "1.0",
    };
    const jsonString = JSON.stringify(values);

    const key = CryptoJS.enc.Utf8.parse(TOKEN_DECRYPT_KEY);
    const iv = CryptoJS.lib.WordArray.create(key.words.slice(0, 4), 16); // 4 words * 4 bytes = 16 bytes
    const encrypted = CryptoJS.AES.encrypt(jsonString, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const encryptedBase64 = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    // const response = await otpGeneration({ request: encryptedBase64 });
    const response = await otpResend({ request: encryptedBase64 });
    const {
      status,
      data: { otpRequest: otpReq },
    } = response;
    if (status === 200) {
      setOtpRequest(otpReq);

    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  // const formik = useFormik({
    // initialValues,
    // validationSchema: loginSchema,
    // onSubmit: async (values, { setStatus, setSubmitting }) => {
    //   setLoading(true);
    //   try {
    //     const {
    //       data: { data: auth },
    //     } = await login(values.email, values.password);
    //     saveAuth(auth);
    //     setLoading(false);
    //     const {
    //       data: { data: user },
    //     } = await getUserByToken(auth);

    //     const {
    //       data: { data: permission },
    //     } = await getUserPermission(auth);
    //     savePermissions(permission);

    //     setCurrentUser(user);
    //     setUser({ email: values.email });
    //     console.log("auth:", auth)

    //   } catch (error) {
    //     console.error(error);
    //     saveAuth(undefined);
    //     setStatus("The login details are incorrect");
    //     setSubmitting(false);
    //     setLoading(false);
    //   }
    // },
  // });




  return (
    <form
      className="form w-100"
      // onSubmit={formik.handleSubmit}
      // noValidate
      // id="kt_login_signin_form"
    >

      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">Sign In</h1>
        <div className="text-gray-500 fw-semibold fs-6">Unlock the Power of Streaming</div>
      </div>

    {!isOtpLogin ? (
    <>
      <div className="fv-row mb-8">
        <input
          placeholder="Email"
          className={clsx(
            "form-control bg-transparent"
          )}
          type="email"
          name="email"
          autoComplete="off"
          value={email}
          onChange={(event) => onEmailChange(event)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
            }
          }}
        />
      {mailError && (
        <p className="text-danger fw-semibold fs-6 mt-2">{mailError}</p>
      )}
      </div>

      <div className="d-grid mb-10">
        <button
          type="button"
          className="btn btn-primary"
          disabled={!email || !validRegEmail.test(email)}
          onClick={onEmailSubmit}
        >
          {!loading && <span className="indicator-label">Get OTP</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      </>
    ) : (
      <>
      <div>
      <p className="otp-sent text-gray-800 fw-bold fs-6">
       An OTP email has been sent to <span className="text-primary" >{email}.</span> Please enter the code to verify your login.
      </p>
      <div className="otp-boxes">
        <OTPInputGroup
          value={otp}
          valueLength={5}
          onChange={handleOTPValue}
        />
      </div>
      <Timer
        handleResendOTP={resendOTP}
        counter={counter}
        setCounter={setCounter}
      />
      {otpError && (
        <p className="text-danger fw-semibold fs-6 mt-2">{otpError}</p>
      )}

      <div className='d-flex flex-wrap justify-content-center pb-lg-0 mt-8'>
        <button 
        type="button"
        className='btn btn-primary me-4'
        onClick={() => onOtpSumbit()}
        >
          <span 
          className='indicator-label'
          >
            Submit
          </span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
          <button
          type="button"
            className='btn btn-light'
            onClick={() => handleOtp()}
          >
            Cancel
          </button>
      </div>

      </div>
      </>
    )}



      <style>
          {`
            input::-ms-reveal,
            input::-ms-clear {
            display: none;
            }
          `}
      </style>
      {/* {(loading && <LoadingSpinner/>)} */}
    </form>
  );
}
